import React, { useEffect, useRef, useState } from 'react'
import './LocationReviews.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useLocation, useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'
import LocationReviewsData from './LocationReviewsData'
// import GoogleMapReact from "google-map-react";

//
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Trust from '../../Images/check.jpg'
import Porch from '../../Images/Porch.png'

export default function LocationReviews({CitiePhone}) {
    const location = useLocation()


    return (
        <div className="LocationReviews">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Chimney Sweep Reviews 5 Stars Based on 1800 Reviews | Experts BHO Chimney Sweep</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`$BHO Chimney Sweep Reviews | 5 Stars Based on 1800 Reviews"} | Experts BHO Chimney Sweep`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`$BHO Chimney Sweep Reviews | 5 Stars Based on 1800 Reviews"} | Experts BHO Chimney Sweep`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.bhochimneysweep.com.com/reviews/local/${location.pathname.split('/')[2]}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={`Discover Our Reviews At $BHO Chimney Sweep Location | 5 Stars Based on 1800 Reviews"} Chimney Sweep Repair in $BHO, area and surrounding cities.`} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={`Discover Our Reviews At $BHO Chimney Sweep Location | 5 Stars Based on 1800 Reviews"} Chimney Sweep Repair in $BHO, area and surrounding cities.`} data-react-helmet="true" />
                <meta name="keywords" content="Chimney Sweeping, Chimney Sweep, Chimney Sweep inspection, Chimney Sweep repair, Chimney Sweep maintenance, Creosote removal, Chimney Sweep safety, Chimney Sweep flue Repair, Soot removal, Chimney Sweep Maintenance Locations" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`BHO Chimney Sweep`} data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Hear What Satisfied Customers Have To Say About BHO Chimney Sweep</h1>
                    <h2>Welcome to BHO Top Chimney Sweep - Read Our Reviews!</h2>
                    <span>
                        <a href={`tel:${CitiePhone}`}>CALL {CitiePhone}<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <div className="GoogleButt">
                        <h6>5 Stars - Based on 1,800 Reviews For BHO Chimney Sweep Repair</h6>
                    </div>
                    <div className="BookAnAppointmentMainComponentEmergery">
                        <div className="BookAnAppointmentMainComponentRates">
                            <div className="BookAnAppointmentMainComponentRatesImages">
                                <img src={YELP} alt="BHO Air Duct Cleaning Yelp Review" />
                                <img src={Angie} alt="BHO Air Duct Cleaning Angie Review" />
                                <img src={HomeAdvisor} alt="BHO Air Duct Cleaning HomeAdvisor Review" />
                                <img src={BBB} alt="BHO Air Duct Cleaning BBB Review" />
                                <img src={Google} alt="BHO Air Duct Cleaning Google Review" />
                                <img src={Porch} alt="BHO Air Duct Cleaning Porch Review" />
                                <img src={Trust} alt="BHO Air Duct Cleaning Trust Review" />
                            </div>
                            <h1>Customer Testimonials</h1>
                            <h2>⭐⭐⭐⭐⭐</h2>
                            <h3>
                                I’ve used BHO Chimney Sweep for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.
                                <a href={`/reviews/local`}>MORE FROM OUR CUSTOMERS &gt;</a>
                            </h3>

                        </div>
                        <div className="BookAnAppointmentMainComponentEmergeryBox">
                            <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="LocationReviewsListMap">
                <div className="LocationReviewsLsit">
                    {LocationReviewsData.map((A, index) =>
                        <div key={index} className="LocationReviewsBox">
                            <div className="LocationReviewsBoxHeader">
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>{A.service_type}</h1>
                                    <h2>BHO Chimney Sweep</h2>
                                </div>
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>⭐⭐⭐⭐⭐</h1>
                                    <h2>{A.date}</h2>
                                </div>
                            </div>

                            <div className="LocationReviewsBoxLeft">
                                <h3>Open Now 24/7 • Top Rated • Same Day Service</h3>
                                <h2>Review Rated 5 By {A.customer_name}</h2>
                                <h4>{A.review}</h4>
                                <div className="LocationReviewsBoxLeftBox">
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Service Provided:</h5>
                                        <h6>{A.service_type}</h6>
                                    </div>
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Technician</h5>
                                        <h6>{A.tech_name}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <NavigatorPath />
        </div>
    )
}

