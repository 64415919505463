import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BookAnAppointment.scss';
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/check.jpg'
import jQuery from 'jquery';

import Porch from '../../Images/Porch.png'
import Image1Logo from '../../ImagesLogos/Image1Logo.png'
const BookAnAppointment = ({ CitiePhone, CitiesData, locationsMain }) => {
    const [Page1, setPage1] = useState(true);
    const [Page2, setPage2] = useState(false);
    const [Page3, setPage3] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [response, setResponse] = useState({});
    const [CustInfoUpdate, setCustInfoUpdate] = useState(false);
    const url = new URL(window.location.href);

    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney/Fireplace Inspection $69',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: CitiesData && CitiesData.City ? CitiesData.Full.split(',')[1].replace(' ', '') : 'Alabama',
        Zipcode: '',
        Note: ''
    });
    const FinalMessage = `
      SPG: ${url.searchParams.get('spg') == "g" ? "Google" : url.searchParams.get('spg') == "m" ? "Microsoft" : "Null"} | ${url.searchParams.get('ct') == "pmax" ? "pMax" : "Search"}
      Company name: BHO Chimney Sweep
      Name: ${CustInfo.FirstName + ' ' + CustInfo.LastName}
      Phone: ${CustInfo.PhoneNumber}
      Address: ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}
      Type: ${CustInfo.Service}
      Note: ${CustInfo.Note}
      The Job date: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time.split('-')[0]}
      The Job end date: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time.split('-')[1]}
      Job type: Chimney Sweep
      Job source: Schedule Online
      `
    //   

    const location = useLocation()
    const navigate = useNavigate()
    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_n0o8yjw', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setPage1(false)
                setPage2(false)
                setPage3(true)
                setFormSubmiting(false)
                {
                    CitiesData && CitiesData.City ?
                        navigate(`/thanks${location.search}name=${CustInfo.FirstName}&message=Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} between ${CustInfo.Time} at ${CustInfo.Address1 + ', ' + CustInfo.City}`)
                        :
                        navigate(`/thanks?name=${CustInfo.FirstName}&message=Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} between ${CustInfo.Time} at ${CustInfo.Address1 + ', ' + CustInfo.City}`)
                }
                sentMessage()
            }, (error) => {
                console.log(error.text);
            });
    }
    const sentMessage = async () => {
        const res = await fetch('https://api.callrail.com/v3/a/317-421-447/text-messages.json', {
            method: 'POST',
            headers: {
                'Authorization': 'Token token=dd02c8e45b65e07d612f8a21a51d9e1d',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'customer_phone_number': `${CustInfo.PhoneNumber.replace(/-/g, '')}`,
                'tracking_number': `${CitiesData && CitiesData.City ? CitiePhone.replace(/-/g, '') : '8665011614'}`,
                'content': `
Hi ${CustInfo.FirstName} 😊,
Thank you for booking your service with BHO Chimney Sweep! Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} Between ${CustInfo.Time} At ${CustInfo.Address1 + ', ' + CustInfo.City}

Our Technician will reach you out 30Min in advance at the appointment day.

Best regards 🤝
BHO Chimney Sweep.
                `,
                'company_id': '653209804'
            })
        })
        const data = await res.json()
    }


    useEffect(() => {
        (async () => {
            try {
                const searchKeyword = `I need from you 32 words about the "${url.searchParams.get('kd')}" how we "bho chimney sweep" can help our clients with that kind of service, give me that as json file: title 3 words (include in the title words from the keyword, remove null and bho chimmney sweep from title)(if no keywords just do for chimney sweep) and body`
                const apiKey = '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'; // Replace with your actual API key
                const resChat = await fetch(`https://api.openai.com/v1/chat/completions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                    },
                    body: JSON.stringify({
                        'model': 'gpt-3.5-turbo',
                        'messages': [
                            {
                                'role': 'user',
                                'content': searchKeyword
                            }
                        ]
                    })
                })
                const dataChat = await resChat.json()
                
                setResponse(JSON.parse(dataChat.choices[0].message.content))
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);


    return (
        <div className="BookAnAppointmentMainComponent" style={location.pathname == '/book' ? { "min-height": "100vh" } : null}>
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Schedule Chimney Service Online | BHO Chimney Sweep | 866-501-1614</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Schedule Chimney Service Online | BHO Chimney Sweep | 866-501-1614" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Schedule Chimney Service Online | BHO Chimney Sweep | 866-501-1614" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />


                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhochimneysweep.com/book" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Easily schedule your professional chimney service online with BHO Chimney Sweep. Book inspections, cleanings, and repairs at your convenience to keep your chimney safe and efficient." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Easily schedule your professional chimney service online with BHO Chimney Sweep. Book inspections, cleanings, and repairs at your convenience to keep your chimney safe and efficient." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Schedule Chimney Service Online | BHO Chimney Sweep | 866-501-1614" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rainp Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="BookAnAppointmentMainComponentTop">
                <div className="BookAnAppointmentMainComponentTopLeft">
                    <h1>SCHEDULE SERVICE ONLINE</h1>
                    <h2>Servicing <b>{CitiesData && CitiesData.City ? CitiesData.Full.toUpperCase() : "Your Area"}</b> And Surrounding Areas - BHO CHIMNEY SWEEP</h2>
                    <h2>No extra charge for appointments on nights, weekends or holidays</h2>
                </div>
            </div>
            <div className='BookAnAppointmentMainComponentTopMain'>
                <div className="BookAnAppointmentMainComponentMaps">
                    <h4 style={Page1 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>1. Information </h4>
                    <h4 style={Page2 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>2. Address </h4>
                    <h4 style={Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>3. Note </h4>
                </div>
                {
                    Page1 ?
                        <div className="BookAnAppointmentMainComponentPage1">
                            <div className="BookAnAppointmentMainRightFullName">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Full Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.FirstName}
                                        className='BookAnAppointmentMainRightBoxFirstName'
                                        onChange={(e) => {
                                            CustInfo.FirstName = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="tel" />
                                </div>
                                {/* <div className="BookAnAppointmentMainRightBox">
                                    <h4>Last Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.LastName}
                                        className='BookAnAppointmentMainRightBoxLastName'
                                        onChange={(e) => {
                                            CustInfo.LastName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="text" />
                                </div> */}
                            </div>
                            {/* <div className="BookAnAppointmentMainRightPhoneAndMaill">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="tel" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Email Address (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Email}
                                        onChange={(e) => {
                                            CustInfo.Email = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="text" />
                                </div>
                            </div> */}
                            <div className="BookAnAppointmentMainRightDateAndTime">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Date <span>*</span></h4>
                                    <input

                                        defaultValue={CustInfo.Date}
                                        className='BookAnAppointmentMainRightBoxDate'
                                        onChange={(e) => {
                                            CustInfo.Date = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="date" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Time <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Time}
                                        className='BookAnAppointmentMainRightBoxTime'
                                        onChange={(e) => {
                                            CustInfo.Time = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} >
                                        <option value="8:00AM-11:00AM">8:00AM-11:00AM</option>
                                        <option value="11:00AM-01:00PM">11:00AM-01:00PM</option>
                                        <option value="01:00PM-03:00PM">01:00PM-04:00PM</option>
                                        <option value="04:00PM-07:00PM">04:00PM-07:00PM</option>
                                        <option value="Ask for a Representative">Ask for a Representative</option>
                                    </select>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    if (!CustInfo.FirstName) { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "2px solid #6f6f6f61"; }
                                    // if (!CustInfo.LastName) { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.PhoneNumber) { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.Date) { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.Time) { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "2px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.FirstName &&
                                        // CustInfo.LastName &&
                                        CustInfo.PhoneNumber &&
                                        CustInfo.Service &&
                                        CustInfo.Date &&
                                        CustInfo.Time
                                    ) {
                                        setPage1(false)
                                        setPage2(true)
                                    } else {
                                        console.log('no')
                                    }
                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page2 ?
                        <div className="BookAnAppointmentMainComponentPage2">
                            <div className="BookAnAppointmentMainRightAddress1">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 1 <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxAddress1'
                                        defaultValue={CustInfo.Address1}
                                        onChange={(e) => {
                                            CustInfo.Address1 = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress2">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 2 (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Address2}
                                        onChange={(e) => {
                                            CustInfo.Address2 = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress3">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>City <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxCity'
                                        defaultValue={CustInfo.City}
                                        onChange={(e) => {
                                            CustInfo.City = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>State <span>*</span></h4>
                                    <select
                                        defaultValue={CitiesData && CitiesData.City ? CitiesData.Full.split(',')[1].replace(' ', '') : 'Alabama'}
                                        onChange={(e) => {
                                            CustInfo.State = e.target.value
                                            setCustInfoUpdate(e => !e)
                                        }}>
                                        {/* <option value="" disabled selected>Select your State</option> */}
                                        <option value="Alabama">Alabama</option>
                                        <option value="Alaska">Alaska</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="Arkansas">Arkansas</option>
                                        <option value="California">California</option>
                                        <option value="Colorado">Colorado</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Delaware">Delaware</option>
                                        <option value="District Of Columbia">District Of Columbia</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Gecomia">Gecomia</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Illinois">Illinois</option>
                                        <option value="Indiana">Indiana</option>
                                        <option value="Iowa">Iowa</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="Kentucky">Kentucky</option>
                                        <option value="Louisiana">Louisiana</option>
                                        <option value="Maine">Maine</option>
                                        <option value="Maryland">Maryland</option>
                                        <option value="Massachusetts">Massachusetts</option>
                                        <option value="Michigan">Michigan</option>
                                        <option value="Minnesota">Minnesota</option>
                                        <option value="Mississippi">Mississippi</option>
                                        <option value="Missouri">Missouri</option>
                                        <option value="Montana">Montana</option>
                                        <option value="Nebraska">Nebraska</option>
                                        <option value="Nevada">Nevada</option>
                                        <option value="New Hampshire">New Hampshire</option>
                                        <option value="New Jersey">New Jersey</option>
                                        <option value="New Mexico">New Mexico</option>
                                        <option value="New York">New York</option>
                                        <option value="Northrolina">Northrolina</option>
                                        <option value="North Dakota">North Dakota</option>
                                        <option value="Ohio">Ohio</option>
                                        <option value="Oklahoma">Oklahoma</option>
                                        <option value="Oregon">Oregon</option>
                                        <option value="Pennsylvania">Pennsylvania</option>
                                        <option value="Rhode Island">Rhode Island</option>
                                        <option value="Southrolina">Southrolina</option>
                                        <option value="South Dakota">South Dakota</option>
                                        <option value="Tennessee">Tennessee</option>
                                        <option value="Texas">Texas</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Vermont">Vermont</option>
                                        <option value="Virginia">Virginia</option>
                                        <option value="Washington">Washington</option>
                                        <option value="West Virginia">West Virginia</option>
                                        <option value="Wisconsin">Wisconsin</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </select>
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Zipcode <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxZipcode'
                                        defaultValue={CustInfo.Zipcode}
                                        onChange={(e) => {
                                            CustInfo.Zipcode = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="tel" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    setPage1(true)
                                    setPage2(false)
                                    setPage3(false)
                                }}>PREVIOUS</button>
                                <button onClick={() => {

                                    if (!CustInfo.Address1) { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.City) { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.Zipcode) { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "2px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.Address1 &&
                                        CustInfo.City &&
                                        CustInfo.Zipcode
                                    ) {
                                        setPage1(false)
                                        setPage2(false)
                                        setPage3(true)
                                    } else {
                                        console.log('no')
                                    }

                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page3 ?
                        <div className="BookAnAppointmentMainComponentPage3">

                            <div className="BookAnAppointmentMainRightNote">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Note (Optional)</h4>
                                    <textarea
                                        defaultValue={CustInfo.Note}
                                        onChange={(e) => {
                                            CustInfo.Note = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightServices">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Service Needed <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Service}
                                        onChange={(e) => {
                                            CustInfo.Service = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} name="" id="">
                                        <option value="Chimney/Fireplace Inspection $69">Chimney/Fireplace Inspection $69</option>
                                    </select>
                                    <h3>Technician will provide inspection report on-site. Payment required only if/when service is done.</h3>
                                    <h3>After the inspection, our technician will likely complete any additional service during the same visit, saving you time and hassle!</h3>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {
                                    setPage1(false)
                                    setPage2(true)
                                    setPage3(false)
                                }}>PREVIOUS</button>

                                {
                                    !FormSubmiting ?
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input type="hidden" name="from_name" defaultValue={FinalMessage} />
                                            {/* Cust Info */}
                                            <input type="hidden" name="Date" defaultValue={CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time} />
                                            <input type="hidden" name="Service_Type" defaultValue={CustInfo.Service} />
                                            <input type="hidden" name="Send_To" defaultValue={CustInfo.Email} />
                                            <input type="hidden" name="Cust_Name" defaultValue={CustInfo.FirstName} />
                                            <input type="hidden" name="Company_From" defaultValue={`BHO Chimney Sweep`} />
                                            <input type="hidden" name="Company" defaultValue={`BHO Chimney Sweep | ${CustInfo.City + ', ' + CustInfo.State}`} />
                                            <input type="submit" value="SEND" />
                                        </form>
                                        :
                                        null
                                }

                                {
                                    FormSubmiting ?
                                        <button class="buttonload">
                                            <i class="fa fa-refresh fa-spin"></i>Loading
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        : null
                }
                {location.pathname == "/local" ? null : <div className="BookAnAppointmentMainComponentEmergery">

                    <div className="BookAnAppointmentMainComponentRates">
                        <div className="BookAnAppointmentMainComponentRatesImages">
                            <img src={YELP} alt="BHO Air Duct Cleaning Yelp Review" />
                            <img src={Angie} alt="BHO Air Duct Cleaning Angie Review" />
                            <img src={HomeAdvisor} alt="BHO Air Duct Cleaning HomeAdvisor Review" />
                            <img src={BBB} alt="BHO Air Duct Cleaning BBB Review" />
                            <img src={Google} alt="BHO Air Duct Cleaning Google Review" />
                            <img src={Porch} alt="BHO Air Duct Cleaning Porch Review" />
                            <img src={Trust} alt="BHO Air Duct Cleaning Trust Review" />
                        </div>
                        <h1>Customer Testimonials</h1>
                        <h2>⭐⭐⭐⭐⭐</h2>
                        <h3>I’ve used BHO Chimney Sweep for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.</h3>
                    </div>
                    <div className="BookAnAppointmentMainComponentEmergeryBox">
                        <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                    </div>
                </div>
                }
            </div>
            {/* MainBanner2Top2 */}
            {location.pathname == "/book" ?
                <div className="MainBanner2Top2" id="ourservices">
                    <div className="MainBanner2TopBox">
                        <div className="MainBanner2TopBoxBack">
                            {/*  */}
                        </div>
                        <div className="MainBanner2TopBoxMain">
                            <div className="MainBanner2TopBoxMenu">
                                <a className="MainBanner2TopBoxBox">
                                    <img src={require(`../../Images/chimneycleaning.png`)} alt="Chimney Cleaning" />
                                    <h3>Chimney Cleaning</h3>
                                    <h4>and Fireplace Cleaning</h4>
                                    <h2>Remove creosote buildup and debris for a safer, more efficient fireplace and improved indoor air quality.</h2>
                                </a>
                                <a className="MainBanner2TopBoxBox">
                                    <img src={require(`../../Images/chimneyinspection.png`)} alt="Chimney Cleaning" />
                                    <h3>Chimney Inspection</h3>
                                    <h4>and Fireplace Inspection</h4>
                                    <h2>Ensure your chimney’s structural integrity and functionality with a thorough inspection to prevent hazards and issues.</h2>
                                </a>
                                <a className="MainBanner2TopBoxBox">
                                    <img src={require(`../../Images/chimneymaintenance.png`)} alt="Chimney Cleaning" />
                                    <h3>Chimney Maintenance</h3>
                                    <h4>and Fireplace Maintenance</h4>
                                    <h2>Regular maintenance prolongs chimney life, ensuring safety, efficiency, and preventing costly future repairs.</h2>
                                </a>
                                <a className="MainBanner2TopBoxBox">
                                    <img src={require(`../../Images/chimneyrepair.png`)} alt="Chimney Cleaning" />
                                    <h3>Chimney Repair</h3>
                                    <h4>and Fireplace Repair</h4>
                                    <h2>Fix cracks, leaks, Gas  Fireplace Repair and structural issues to restore your chimney’s safety, functionality, and aesthetic appeal.</h2>
                                </a>
                            </div>
                            <div className="MainBanner2TopBoxMainChat">
                                <img src={require(`../../ImagesLogos/Image1Logo.png`)} />
                                <div className="MainBanner2TopBoxMainChatText">
                                    <h4>{response.title}</h4>
                                    <h3>{response.body}</h3>
                                    <h5>Call us at <a href={`tel:${CitiePhone}`}>{CitiePhone}</a> or fill out the <a href={`/book${location.search}`}>online form</a> to schedule your visit.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null}
            {/* // HomePageGoogleFooter */}
            {location.pathname == "/book" ?
                < div className="HomePageGoogleFooter">
                    <div className="HomePageGoogleFooterTop">
                        <div className="HomePageGoogleFooterTopTitle">
                            <img src={require(`../../Images/Music.png`)} />
                            <h1>BHO Sweep Clean And Neat<br />Can’t Be Beat!</h1>
                            <img src={require(`../../Images/Music.png`)} />
                        </div>
                        <div className="HomePageGoogleFooterTopImg">
                            <img src={require(`../../ImagesLogos/Image1Logo.png`)} />
                        </div>
                    </div>
                    <div className="HomePageGoogleFooterBottom">
                        <div className="HomePageGoogleFooterBottomTop">
                            <img src={require(`../../ImagesLogos/Banner2Logo.png`)} />
                            <p>Copyright 1975-2024 BHO Chimney Sweep. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</p>
                            <div className="SiteMapDiv">
                                <h1>Privacy</h1>
                                <a href="/privacy-policy">Privacy Policy</a>
                                <a href="/terms-and-conditions">Terms And Conditions</a>
                            </div>
                        </div>
                        {locationsMain.find(a => a.Phone == CitiePhone) ?
                            <div className="HomePageGoogleFooterMiddle">
                                {locationsMain.find(a => a.Phone == CitiePhone).Address.map((A, index) =>
                                    <div className="HomePageGoogleLocationLeftBox">
                                        <h4>{A.Location1}</h4>
                                        <h5>{A.Address1}</h5>
                                        <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                                    </div>
                                )}
                            </div>
                            : null}
                        <div className="HomePageGoogleFooterBottomBottom">
                            <span>
                                <img src={require(`../../Images/tag.png`)} alt="" />
                                <a>www.bhochimneysweep.com</a>
                            </span>
                            <span>
                                <img src={require(`../../Images/tag.png`)} alt="" />
                                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                            </span>
                            <span>
                                <img src={require(`../../Images/tag.png`)} alt="" />
                                <a>info@bhochimneysweep.com</a>
                            </span>
                        </div>
                    </div>
                </div>
                :
                null}
        </div >
    );
}

export default BookAnAppointment;
